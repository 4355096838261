<template>
    <v-dialog
        v-if="isVisible"
        v-model="isVisible"
        transition="dialog-top-transition"
        scrollable
        persistent
        max-width="700"
    >
        <v-card class="modal-maincard">

            <v-toolbar dark flat dense>
                <v-toolbar-title>{{ $t(title) }}</v-toolbar-title>
            </v-toolbar>

            <v-card-text v-if="isPending" >

                <v-col
                    class="text-subtitle-1 text-center"
                    cols="12"
                >
                    {{$t("Загрузка...")}}
                </v-col>

                <v-col cols="12">
                    <v-progress-linear
                        color="#a94442"
                        indeterminate
                        rounded
                        height="6"
                    ></v-progress-linear>
                </v-col>

            </v-card-text>
            <v-card-text
                v-else
                class="wrapperFormModal"
            >
                <v-card flat>
                    <v-card-text>
                        
                        <v-form ref="form" lazy-validation>

                            <v-row no-gutters>

                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label f-label-more-rows">
                                        {{$t("Наименование_объекта")}}                                        
                                    </label>
                                </v-col>

                                <v-col cols="12" sm="12" md="9">
                                    <v-textarea 
                                        v-model="_objectName"
                                        rows="3"
                                        no-resize
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        :rules="_objectName ? requiredRule : []"
                                    />
                                </v-col>

                            </v-row>

                            <v-row no-gutters>

                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label f-label-more-rows">
                                        {{$t("Адрес_объекта")}}                                        
                                    </label>
                                </v-col>

                                <v-col cols="12" sm="12" md="9">
                                    <v-textarea 
                                        v-model="_objectAddress"
                                        rows="3"
                                        no-resize
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        :rules="_objectAddress ? requiredRule : []"
                                    />
                                </v-col>

                            </v-row>

                            <v-row no-gutters>

                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label f-label-more-rows">
                                        {{$t("Точка_подключения")}}                                        
                                    </label>
                                </v-col>

                                <v-col cols="12" sm="12" md="9">
                                    <v-textarea 
                                        v-model="_connectionPoint"
                                        rows="3"
                                        no-resize
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        :rules="_connectionPoint ? requiredRule : []"
                                    />
                                </v-col>

                            </v-row>

                            <v-row no-gutters v-if="isExtService">

                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label f-label-more-rows">
                                        {{$t("Общее_количество_сточных_вод")}}                                        
                                    </label>
                                </v-col>

                                <v-col cols="12" sm="12" md="9">
                                    <v-text-field 
                                        v-model="_totalWastewater"
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        type="number"
                                        class="cust-inputnumber"
                                        min="0"
                                        :rules="_totalWastewater ? requiredRule : []"
                                    />
                                </v-col>

                            </v-row>

                            <v-row no-gutters  v-if="isExtService">

                                <v-col cols="12" sm="12" md="3">
                                    <label class="f-label f-label-more-rows">
                                        {{$t("В_том_числе")}}                                        
                                    </label>
                                </v-col>

                                <v-col cols="12" sm="12" md="9">
                                    <v-text-field
                                        v-model="_including"
                                        hide-details
                                        required
                                        outlined
                                        dense
                                        :rules="_including ? requiredRule : []"
                                    />
                                </v-col>

                            </v-row>                            

                        </v-form>

                    </v-card-text>
                </v-card>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>

                <v-btn 
                    color="cyan"
                    text
                    depressed
                    @click="ok"
                    v-if="isValid"
                >
                    {{ $t("Сформировать_и_отправить_на_подпись") }} 
                </v-btn>

                <v-btn
                    color="blue-grey"
                    text
                    depressed
                    @click="cancel"
                >
                    {{ $t("Отмена") }}
                </v-btn>

            </v-card-actions>

        </v-card>
    </v-dialog>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';

export default {
    name: "CSCFormAnAnswerDlg",    
    data () {
        return {
            title: "Сформировать_ответ",
        }
    },
    computed: {
        ...mapGetters('dialogs/cscFormAnAnswer', ['isVisible', 'isValid', 'isPending', 'objectName', 'objectAddress', 'connectionPoint', 'isExtService']),
        ...mapGetters({ 
            requiredRule: 'getRequiredRule',            
        }),

        _objectName: {
            get: function() {
                return this.objectName;
            },
            set: function(v) {
                this.$store.commit('dialogs/cscFormAnAnswer/SET_OBJECT_NAME', v); 
                this.validateFormMethod();
            }
        },
        _objectAddress: {
            get: function() {
                return this.objectAddress;
            },
            set: function(v) {
                this.$store.commit('dialogs/cscFormAnAnswer/SET_OBJECT_ADDRESS', v); 
                this.validateFormMethod();
            }
        },
        _connectionPoint: {
            get: function() {
                return this.connectionPoint;
            },
            set: function(v) {
                this.$store.commit('dialogs/cscFormAnAnswer/SET_CONNECTION_POINT', v); 
                this.validateFormMethod();
            }
        },
        _totalWastewater: {
            get: function() {
                return this.totalWastewater;
            },
            set: function(v) {
                this.$store.commit('dialogs/cscFormAnAnswer/SET_TOTAL_WASTEWATER', v); 
                this.validateFormMethod();
            }
        },
        _including: {
            get: function() {
                return this.including;
            },
            set: function(v) {
                this.$store.commit('dialogs/cscFormAnAnswer/SET_INCLUDING', v); 
                this.validateFormMethod();
            }
        }
    },
    methods: {
        ...mapActions('dialogs/cscFormAnAnswer', ['ok', 'cancel']),
        
        validateFormMethod(){
            this.$refs?.form?.validate();    
        }
    }
}
</script>